/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 576px) {
  .search-with-filter {
    justify-content: center !important;
  }

  .search-with-filter .filter-input-group-with-reset {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (min-width: 576px) {
  .search-with-filter {
    justify-content: space-between !important;
  }
}

.form-modal-padding {
  padding-right: 1rem;
}

@media (max-width: 1024px) {
  .form-modal-padding {
    padding-bottom: 4rem;
  }
}

.captcha-canvas-wrapper div:first-child {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.w-fit {
  width: fit-content;
}

.\!w-fit {
  width: fit-content !important;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
