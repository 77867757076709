.dynamic_entry_submodal_container {
  position: fixed;
  left: -350px;
  top: 0;
  width: 350px;
  max-height: 100vh;
  height: 100%;
}

.dynamic_entry_submodal_content {
  background-color: #ffffff;
  padding-bottom: 5rem;
}

.dynamic_entry_badges_container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  max-width: 300px;
  width: 100%;
}

.dynamic_entry_badges_container span {
  word-break: break-all;
  max-width: 300px;
  width: fit-content;
  white-space: wrap;
}

@media (max-width: 760px) {
  .dynamic_entry_submodal_container {
    position: relative;
    top: 0;
    left: 0;
    width: 300px;
    height: 400px !important;
    overflow: hidden;
  }

  .scrollbar_container {
    height: 300px !important;
  }

  .dynamic_entry_submodal_header {
    position: relative;
  }

  .dynamic_entry_badges_container {
    margin-top: 0.5rem;
  }
}
